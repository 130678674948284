import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../components/Messages";
import { ServiceOrder } from "../types/managementServiceOrderTypes";
import { api } from "../services/api";
import { chechServiceOrderStatusFromClassification, dateToShow, formatTimeFromSeconds } from "../utils";
import { LOCAL_STORAGE_KEY } from "../consts";
import { ImageModel } from "../types/managementUserTypes";
import { DEFAULT_VEHICLE_FORM_CHECKLIST_OPTIONS } from "../consts/defaultVehicleFormChecklistOptions";

type ServiceOrderData = {
  id: string;
  name: string;
  type: string;
  status: string;
};

interface GetAllServiceOrders {
  status?: string;
  dateOfEmission?: string;
  numero?: string;
  programacao?: string;
  paginationIndex?: number;
  motorista?: string;
  rowsPerPage?: number;
  scheduleWeek?: string | number;
  range?: {
    from: string | null,
    to: string | null
  }
}

const SKIPLIMIT = 10;

const formatImageObjectToSend = (image: any) => {
  return {
    imagem_name: image?.imagem_name || '',
    imagem_path: image?.imagem_path || '',
    obrigatorio: !!image?.obrigatorio,
  }
}

const formatDataToSendToBack = (serviceOrderData: ServiceOrder, owner?: string) => {
  return {
    prog_semana: Number(serviceOrderData?.scheduleWeek || 0),
    vehicle_inspection_askings: serviceOrderData?.vehicle_inspection_askings?.map((item: any) => ({
      ...item,
      first_image: formatImageObjectToSend(item?.first_image),
      second_image: formatImageObjectToSend(item?.second_image)
    })),
    must_validate_vehicle_form_before: serviceOrderData.must_validate_vehicle_form_before,
    programacao: {
      _id: serviceOrderData.schedule.id,
      nome: serviceOrderData.schedule.name,
      via: serviceOrderData.schedule.via,
      modal: serviceOrderData.schedule.modal,
      carga: serviceOrderData.schedule.typeLoad,
      situacao: serviceOrderData.schedule.situation,
      rotas: serviceOrderData.schedule.routes.map((route) => ({
        _id: route.id,
        nome: route.name,
        remetente: route.sender,
        cep: route.zipCode,
        estado: route.state,
        cidade: route.city,
        bairro: route.neighborhood,
        logradouro: route.street,
        numero: route.number,
        complemento: route.complement,
        referencia: route.reference,
        data_coleta: route.collectDate,
      })),
    },
    arquivos: serviceOrderData.files,
    checklist: {
      _id: serviceOrderData.checklist.id,
      nome: serviceOrderData.checklist.name,
      previsao: serviceOrderData?.checklist?.forecast,
      etapas: serviceOrderData.checklist.steps.map((step: any) => {
        return {
          status: step.status,
          ordem: step.order,
          _id: step.id,
          titulo: step.name,
          localizacao: step.location,
          obrigatorio: step.required,
          tempo_execucao: step.executionTime,
          motorista_description: step?.driverDescription,
          classification: step?.classification,
          pedirPrevisao: step?.askToForecast,
          sendToEmail: step?.sendToEmail,
          showToClient: step?.showToClient,
          imagens: step.images.map((image: any) => {
            return {
              imagem_name: image.imageName,
              imagem_path: image.imagePath,
              file_name: image.fileName,
              localizacao: image.location,
              data_hora: image.dateHour,
              obrigatorio: image.required || image.obrigatorio,
              data_agendamento: image.dateScheduled,
              ordem: image.order,
              isVideo: image?.isVideo
            };
          }),
        };
      }),
    },
    motorista: {
      _id: serviceOrderData.driver.id,
      cracha: serviceOrderData.driver.badge,
      cnh: serviceOrderData.driver.cnhNumber,
      cnh_categoria: serviceOrderData.driver.cnhCategory,
      cnh_validade: serviceOrderData.driver.cnhExpirationDate,
      nome: serviceOrderData.driver?.name,
    },
    veiculos: serviceOrderData.vehicles.map((vehicle) => ({
      _id: vehicle.id,
      nome: vehicle.name,
      veiculo_tipo: {
        nome: vehicle.vehicleType.name,
      },
      renavam: vehicle.renavam,
      chassis: vehicle.chassi,
      placa: vehicle.plate,
      proprietario: vehicle.ownerName,
      motorista_responsavel: vehicle.responsibleDriver,
      cor: vehicle.color,
    })),
    ctac: serviceOrderData.ctac,
    solicitante: serviceOrderData.requester,
    cliente: {
      _id: serviceOrderData.client.id,
      nome: serviceOrderData.client.name,
    },
    navio_aviao: serviceOrderData.shipAirplaneName,
    numero_container: serviceOrderData.containerNumber,
    carga: serviceOrderData.load,
    observacao: serviceOrderData.observation,
    owner: owner || (serviceOrderData as any).owner,
    allocateAnyway: (serviceOrderData as any)?.allocateAnyway,
  }
}

export const useServiceOrder = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [refreshServiceOrders, setRefreshServiceOrders] = useState(false)
  const [serviceOrderData, setServiceOrderData] = useState<ServiceOrder>({
    id: "",
    status: "" as any,
    load: "",
    files: [],
    scheduleWeek: 0,
    checklist: {
      id: "",
      name: "",
      status: "Ativo",
      typeScheduleId: "Selecionar",
      notifyApp: "Sim",
      required: true,
      forecast: "",
      steps: [],
    },
    client: { id: "", name: "" },
    ctac: "",
    emissionDate: "",
    driver: {
      id: "",
      createdAt: "",
      name: "",
      cpf: "",
      badge: "",
      rg: "",
      phoneFirst: "",
      phoneSecond: "",
      email: "",
      cnhNumber: "",
      cnhCategory: "",
      cnhExpirationDate: "",
      status: true,
    },
    shipAirplaneName: "",
    osNumber: "",
    containerNumber: "",
    observation: "",
    occurrences: [],
    schedule: {
      id: "Selecionar",
      name: "",
      via: "",
      modal: "",
      typeLoad: "",
      situation: "",
      status: true,
      routes: [],
    },
    requester: "",
    cancelJustification: '',
    vehicles: [],
    cnpj: "",
    cpf: "",
    vehicle_inspection_askings: DEFAULT_VEHICLE_FORM_CHECKLIST_OPTIONS,
    must_validate_vehicle_form_before: true
  });

  const asyncGetDriver = async (idDriver: string) => {
    try {
      const { data } = await api.get(`motoristas/${idDriver}`);
      setServiceOrderData((prevState) => ({
        ...prevState,
        driver: {
          ...prevState.driver,
          name: data.data.user?.nome,
          cpf: data.data.user?.cpf,
          email: data.data.user?.email,
          phoneFirst: data.data.user?.telefone,
          phoneSecond: data.data.user?.telefone2,
        },
      }));
    } catch (error) {
      console.log(error);
    }
  }

  const getServiceOrder = async (id: string) => {
    const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE);
        
    const isAdmin = userRole?.toString()?.includes('Admin')
      
    try {
      const { data } = await api.get(`ordem-servico/${id}`);

      setServiceOrderData({
        ...serviceOrderData,
        id: data.data._id,
        vehicle_inspection_askings: data.data.vehicle_inspection_askings,
        must_validate_vehicle_form_before: data.data.must_validate_vehicle_form_before,
        status: data.data.status,
        cancelJustification: data.data?.cancel_justificativa,
        load: data.data.carga,
        scheduleWeek: data?.data?.prog_semana,
        files: data.data?.arquivos || [],
        checklist: {
          id: data.data.checklist._id,
          name: data.data.checklist.nome,
          status: data.data.checklist.status ? "Ativo" : "Inativo",
          typeScheduleId: "Selecionar",
          notifyApp: data.data.checklist.notificacao ? "Sim" : "Não",
          required: data.data.checklist.obrigatorio,
          forecast: data?.data?.checklist.previsao,
          steps: data.data.checklist.etapas
            ? data.data.checklist.etapas
                .map((etapa: any) => {
                  return {
                    images: etapa.imagens
                      ? etapa.imagens.map((imagem: any) => {
                          return {
                            imageName: imagem.imagem_name,
                            imagePath: imagem.imagem_path,
                            fileName: imagem.file_name,
                            required: imagem.obrigatorio,
                            location: imagem.localizacao,
                            dateHour: imagem.data_hora,
                            dateScheduled: imagem.data_agendamento,
                            order: imagem.ordem,
                            isVideo: imagem?.isVideo,
                          };
                        })
                        : [],
                    status: etapa.status,
                    name: etapa.titulo,
                    validatedAtAdmin: etapa?.validatedAtAdmin,
                    startedAtAdmin: etapa?.startedAtAdmin,
                    executionTime: etapa.tempo_execucao,
                    required: etapa.obrigatorio,
                    order: etapa.ordem,
                    statusMessage: etapa?.statusMessage,
                    date: etapa?.date,
                    startDate: etapa?.startDate,
                    sendToEmail: etapa?.sendToEmail,
                    showToClient: etapa?.showToClient,
                    askToForecast: etapa?.pedirPrevisao,
                    driverDescription: etapa?.motorista_description,
                    classification: etapa?.classification,
                    location: {
                      latitude: etapa.localizacao.latitude,
                      longitude: etapa.localizacao.longitude,
                    },
                  };
                })
                .sort((step: any, nextStep: any) => {
                  return step.order - nextStep.order;
                })
            : [],
        },
        client: { id: data.data.cliente._id, name: data.data.cliente.nome },
        ctac: data.data.ctac,
        emissionDate: dateToShow(data.data.emissao_data, true),
        driver: {
          id: data.data.motorista._id,
          createdAt: data.data.motorista.data_cadastro,
          name: data.data.motorista.nome,
          cpf: data.data.motorista.cpf,
          badge: data.data.motorista.cracha,
          rg: data.data.motorista.documento,
          phoneFirst: data.data.motorista.telefone,
          phoneSecond: data.data.motorista.telefone2,
          email: data.data.motorista.email,
          cnhNumber: data.data.motorista.cnh,
          cnhCategory: data.data.motorista.cnh_categoria,
          cnhExpirationDate: data.data.motorista.cnh_validade,
          status: data.data.motorista.status,
        },
        shipAirplaneName: data.data.navio_aviao,
        osNumber: data.data.numero,
        containerNumber: data.data.numero_container,
        observation: data.data.observacao,
        occurrences: data.data.ocorrencias,
        schedule: {
          id: data.data.programacao._id,
          name: data.data.programacao.nome,
          via: data.data.programacao.via,
          modal: data.data.programacao.modal,
          typeLoad: data.data.programacao.carga,
          situation: data.data.programacao.situacao,
          status: data.data.programacao.status,
          routes: data.data.programacao.rotas.map((rota: any) => ({
            id: rota._id,
            name: rota.nome,
            sender: rota.remetente,
            zipCode: rota.cep,
            state: rota.estado,
            city: rota.cidade,
            neighborhood: rota.bairro,
            street: rota.logradouro,
            number: rota.numero,
            complement: rota.complemento,
            reference: rota.referencia,
            collectDate: rota.data_coleta,
          })),
        },
        requester: data.data.solicitante,
        vehicles: data.data.veiculos.map((veiculo: any) => ({
          id: veiculo._id,
          name: veiculo.nome,
          renavam: veiculo.renavam,
          chassi: veiculo.chassis,
          plate: veiculo.placa,
          client: veiculo.empresa,
          color: veiculo.cor,
          createdAt: veiculo.data_cadastro,
          status: veiculo.status,
          ownerName: veiculo.proprietario,
          responsibleDriver: veiculo.motorista_responsavel,
          vehicleType: {
            id: veiculo.veiculo_tipo._id,
            name: veiculo.veiculo_tipo.nome,
          },
        })),
      });
      const idDriver = data.data.motorista._id;

      if(isAdmin) {
        asyncGetDriver(idDriver)
      }
      
      setLoading(false);
    } catch (error: any) {
      const response = error?.response
      console.log(error);
      setLoading(false);
    }
  };

  const addServiceOrder = async (serviceOrderData: ServiceOrder) => {
    const UserStorage = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ID);

    if (!UserStorage) {
      return;
    }
    setLoading(true);
    try {

      await api.post("ordem-servico", formatDataToSendToBack(serviceOrderData, JSON.parse(UserStorage)));
      navigate("/home");
      successMessage("Ordem de serviço adicionada com sucesso!");
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      if(error?.response?.data?.message[0]) {
        setLoading(false);
        if(typeof error?.response?.data?.message[0] === "object") {
          errorMessage(error?.response?.data?.message[0][0]);
          return
        }
        errorMessage(error?.response?.data?.message[0])
        return
      }
      errorMessage("Não foi possível adicionar ordem de serviço");
      setLoading(false);
    }
  }; 

  const updateServiceOrder = async (
    serviceOrderData: ServiceOrder, 
    customFeedBackMessages?: {
      success: string,
      error: string
    },
    updatingChecklist?: boolean, 
  ) => {
    setLoading(true);

    try {
      await api.patch(`ordem-servico/${id}`, {
        ...formatDataToSendToBack(serviceOrderData),
        status: serviceOrderData.status
      });
      if(!updatingChecklist) navigate("/home");
      successMessage(customFeedBackMessages?.success || "Ordem de serviço atualizada com sucesso!");
      setRefreshServiceOrders(prev => !prev)
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMessage(customFeedBackMessages?.error || "Não foi possível atualizar ordem de serviço");
      setLoading(false);
    }
  };

  const deleteServiceOrder = async (id: string) => {
    setLoading(true);
    try {
      await api.delete(`ordem-servico/${id}`);
      getAllServiceOrders();
      setLoading(false);
      setRefreshServiceOrders(prev => !prev)
      successMessage("Ordem de serviço excluída com sucesso!");
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível excluír ordem de serviço");
      setLoading(false);
    }
  };

  const [loadingOnCancel, setLoadingOnCancel] = useState(false)

  const cancelServiceOrder = async (id: string, cancelJustification: string) => {
    setLoadingOnCancel(true);
    setLoading(true);

    try {
      await api.post(`ordem-servico/${id}/cancel`, {
        cancel_justificativa: cancelJustification
      });
      getAllServiceOrders();
      setRefreshServiceOrders(prev => !prev)
      successMessage("Ordem de serviço cancelada com sucesso!");
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível cancelar ordem de serviço");
    } finally {
      setLoadingOnCancel(false)
      setLoading(false);
    }
  };

  const unCancelServiceOrder = async (id: string) => {
    setLoading(true);
    try {
      await api.post(`ordem-servico/${id}/unCancel`);
      getAllServiceOrders();
      setLoading(false);
      setRefreshServiceOrders(prev => !prev)
      successMessage("Ordem de serviço descancelada com sucesso!");
    } catch (error) {
      console.log(error);
      errorMessage("Não foi possível descancelar ordem de serviço");
      setLoading(false);
    }
  };

  const handleSearchClient = async (cnpj: string) => {
    try {
      const { data } = await api.get(`/clientes/cnpj/${cnpj}`);
      setServiceOrderData((prevState) => ({
        ...prevState,
        client: { id: data.data._id, name: data.data.nome },
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const [listServiceOrders, setListServiceOrders] = useState<
    ServiceOrderData[]
  >([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageQuantity: 1,
  });
  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getAllServiceOrders(value);
  };

  const getServiceOrders = async <T>({status, dateOfEmission, numero, programacao, paginationIndex, motorista, range, scheduleWeek, rowsPerPage = 10} : GetAllServiceOrders) => {
    let result: any = [];
    let totalItems = 0;
    setLoadingData(true)

    const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE);
    const client_id = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_CLIENT_ID)
      
    const isClient = userRole?.toString()?.includes('Cliente')
    
    const statusToQuery = `${status ? `&status=${status}` : ''}`;
    const dateOfEmissionToQuery =  `${dateOfEmission ? `&emissao_data=${dateOfEmission}` : ""}`
    const numeroToQuery = `${numero ? `&numero=${numero}` : ''}`
    const programacaoToQuery = `${programacao ? `&programacao_id=${programacao}` : ''}`;
    const motoristaToQuery = `${motorista ? `&motorista_name=${motorista}` : ''}`;
    const rangeToQuert = `${range?.to ? `&from=${range.from}&to=${range.to}` : ''}`
    const clientToQuery = `${isClient ? `&client_id=${JSON.parse(client_id || '')}`: ''}`
    const scheduleWeekToQuery =  `${scheduleWeek ? `&prog_semana=${scheduleWeek}` : ''}`;

    const queryParams = `${statusToQuery}${dateOfEmissionToQuery}${numeroToQuery}${programacaoToQuery}${motoristaToQuery}${rangeToQuert}${scheduleWeekToQuery}`

    try {
      const response = await api.get(`ordem-servico?limit=${rowsPerPage}&skip=${paginationIndex}${queryParams}${clientToQuery}`);

      if(response.status === 200) {
        const data = response?.data?.data?.data?.map((result: any) => (
          {
            id: result?._id,
            name: result?.numero,
            type: result?.programacao?.nome || "",
            status: result?.status,
            returnStatus: result?.status,
            emissao_data: dateToShow(result?.emissao_data),
            driver: result?.motorista?.nome,
            statusFromClassification: chechServiceOrderStatusFromClassification(result?.checklist?.etapas || [])
          }
        ))

        totalItems = response?.data?.data?.total

        result = [...data]
      }
      setLoadingData(false)
    } catch (error) {
      console.log("Erro", error);
      setLoadingData(false)
    }

    return {
      data: result as T[],
      loading,  
      totalItems,
    };
  }

  interface GetAllIntervalsProps {
    id: string;
    paginationIndex?: number;
    rowsPerPage?: number;
  }

  const getAllIntervals = async ({ id, paginationIndex, rowsPerPage } : GetAllIntervalsProps) => {
    let data = [];
    let loading = true;
    let total = 0;

    try {
      const response = await api.get(`ordem-servico/${id}/list-intervals?limit=${rowsPerPage}&skip=${paginationIndex}`)

      data = response.data?.intervalos.map((item: any) => ({
        startDate: dateToShow(item?.start, true),
        endDate: dateToShow(item?.end, true),
        description: item?.causa,
        duration: formatTimeFromSeconds(item.duration ? item.duration / 1000 : null as any),
        status: item?.isActive,
        location: item?.localizacao,
      }))
      total = response.data?.totalItems

      loading = false

    } catch (error: any) {
      loading = false
      console.log("Erro ao pegar lista de intervalos:", error)
    }

    return {
      data,
      total,
      loading
    }
  }

    const getAllOccurrences = async ({ id, paginationIndex=0, rowsPerPage } : GetAllIntervalsProps) => {
    let data = [];
    let loading = true;
    let total = 0;

    try {
       const response = await api.get(`/ocorrencias/activas?limit=${rowsPerPage}&skip=${paginationIndex}&os_id=${id}`);

      data = response?.data?.data?.data?.map((item: any) => ({
        startDate: dateToShow(item?.createdAt, true),
        endDate: dateToShow(item?.end_date, true),
        description: item?.descricao_motorista || "",
        name: item?.nome,
        status: item?.status,
        location: item?.localizacao,
        id: item?.ordem,
        occurrencyId: item?._id,
      }))

      total = response?.data?.data?.total

      loading = false

    } catch (error: any) {
      loading = false
      console.log("Erro ao pegar lista de intervalos:", error)
    }

    return {
      data,
      total,
      loading
    }
  }

  const getAllServiceOrders = async (currentPage: number = 1) => {
    try {
      const { data } = await api.get(
        `ordem-servico/byStatus?skip=${
          SKIPLIMIT * (currentPage - 1)
        }&limit=${SKIPLIMIT}`
      );
     
      setPagination({
        currentPage: currentPage,
        pageQuantity: Math.ceil(data.data.total / SKIPLIMIT),
      });
      setListServiceOrders(
        data.data.results.map((result: any) => {
          return {
            id: result?._id,
            name: `Ordem de serviço - ${result?.numero}`,
            type: result?.programacao?.nome,
            status: result?.status,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const endServiceOrder = async ({OS_ID, driverID}: {OS_ID: string, driverID: string}) => {
    setLoading(true);
    try {
      await api.patch(
        `ordem-servico/${OS_ID}/end`, {
          localizacao: {
            latitude : 0,
            longitude: 0
          },
          date: new Date(),
          motorista_id: driverID
        }
      );

      successMessage('Ordem de serviço finalizada com sucesso')
      navigate("/home");
      setRefreshServiceOrders(prev => !prev)
    } catch (error: any) {
      const response = error?.response
      if(response && response?.data?.message?.length) {
        return errorMessage(response?.data?.message[0])
      }
      errorMessage('Erro ao tentar finalizar a Ordem de serviço')
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    setLoading,
    pagination,
    handleChangePagination,
    serviceOrderData,
    listServiceOrders,
    getServiceOrder,
    addServiceOrder,
    updateServiceOrder,
    deleteServiceOrder,
    cancelServiceOrder,
    unCancelServiceOrder,
    getAllServiceOrders,
    setServiceOrderData,
    handleSearchClient,
    getServiceOrders,
    loadingData,
    refreshServiceOrders,
    getAllIntervals,
    getAllOccurrences,
    loadingOnCancel,
    endServiceOrder
  };
};
