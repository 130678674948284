import { useState, FC, ReactNode, cloneElement, isValidElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Dropzone, { FileError } from '.';

import {
  Button,
} from "@material-ui/core";
import { boxStyle, buttonsWrapper, dropzoneWrapperStyle, useStyles } from './styles';
import { upload } from '../../services/upload';
import { chooseImage } from './utils';
import SelectedFile from './file';

import { Loading } from "../../components/Loading";

export type DropZoneModalProps = {
  onSave: (fileKey: string, fileType: string, preViewName: string) =>  void
  label?: string
  disabled?: boolean
  fileKey: string
  children?: ReactNode
}

const DropZoneModal: FC<DropZoneModalProps> = ({ onSave, label, disabled, fileKey, children }) => {
  const [open, setOpen] = useState(false);

  const [file, setFile] = useState<File>()
  const [rejectedFile, setRejectedFile] = useState<[File, FileError]>()
 
  const [loading, setLoading] = useState(false)
  
  const handleOpen = () => setOpen(true);

  const onDeleteFile = () => {
    setFile(null as any)
    setRejectedFile(null as any)
  }

  const handleClose = () => {
    setOpen(false)
    setFile(undefined as any)
    setRejectedFile(null as any)
  };

  const handleSave = async () => {
    if(!file) return 

    const {status, imageKey} = await upload(fileKey, file, setLoading, file.type)

    if(status === 200 || status === 201) {
      onSave(imageKey, file.type, file.name)
      handleClose()
    }
  }

  const classes = useStyles()

  const returnChildren = () => {
    if(isValidElement(children)) {
      return cloneElement<any>(children, {onClick: handleOpen })
    }

    return <></>
  }

  return (
    <div>
      {children ? returnChildren() : (
        <Button onClick={handleOpen} disabled={disabled}>
          {label || 'Modal de Upload'}
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={boxStyle}>
          <Typography id="modal-modal-title" variant="h5" fontFamily='Barlow, sans-serif' fontWeight={700} component="h2">
            Upload de Arquivos
          </Typography>

          <Box sx={dropzoneWrapperStyle}>
            <Dropzone 
              onUploadFile={(file) => {
                setFile(file)
              }}
              onRejectedFile={(file, error) => {
                setRejectedFile([file, error])
              }}
            />
          </Box>
          {(rejectedFile?.[0] || file) && 
           <SelectedFile
            file={rejectedFile?.[0] || file}
            error={rejectedFile?.[1]}
            onDeleteFile={onDeleteFile}
           />
          }
          <Box sx={buttonsWrapper}>
            <Button 
              className={classes.buttonCancel}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleSave()}
              disabled={!file?.name}
            >
              {loading ? <Loading/> : 'Upload'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default DropZoneModal
