import {
  setDefaults,
  fromLatLng,
} from "react-geocode";

type ResponseResult = {
  address_components: any[]
  formatted_address: string
  geometry: {
    location: {lat: number, lng: number}
  },
}

export type Response = {
  results: ResponseResult[]
}

export const getAddressFromCoords = async (latitude: number, longetude: number) => {
  let formattedAddress = ""

  setDefaults({
    key: "AIzaSyDCoRI68p_w2yvN1ZuW61Sqo1w2QCuT468", 
    language: "pt-br",
    region: "br",
  } as any);

  try {
    const { results } = (await fromLatLng(latitude, longetude)) as unknown as  Response
  
    formattedAddress = results[0].formatted_address
  } catch (error) {
    console.log(error)
  }

  return {formattedAddress}
}