import { useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";
import { AxiosError } from "axios";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from "@material-ui/icons/";

import { TabMonitoring } from "./Tabs/Monitoring";
import { TabOccurrences } from "./Tabs/Occurrences";
import { TabNotifications } from "./Tabs/Notifications";

import { StepRoute } from "./Steps/Route";
import { StepOverview } from "./Steps/Overview";
import { StepChecklist } from "./Steps/Checklist";
import { StepOperationData } from "./Steps/OperationData";
import { StepDriverAndVehicle } from "./Steps/DriverAndVehicle";

import { useStyles, ColorlibStepIcon } from "./styles";
import { useServiceOrder } from "../../../hooks/useServiceOrder";
import { inCreationOrEditing } from "../../../utils";

import { api } from "../../../services/api";
import { errorMessage } from "../../../components/Messages";
import { ModalConfirm } from "../../../components/Modals/ConfirmModal";
import { Loading } from "../../../components/Loading";
import ChecklistTab from "./Tabs/Checklist";
import IntervalTab from "./Tabs/Interval";
import { LOCAL_STORAGE_KEY } from "../../../consts";
import FilesTab from "./Tabs/files";
import { SERVICE_ORDER_STATUS } from "../../../consts/serviceOrderStatus";

const steps = [
  "Dados da Operação",
  "Rota",
  "Checklist",
  "Motorista e Veículo",
  "Arquivos",
  "Revisão",
];

enum IsModalFor  {
  DRIVER = 'DRIVER',
  VEHICLE = 'VEHICLE',
  FINISH = 'FINISH'
}

const confirmAnywayTexts = (isModalFor: IsModalFor) => {
  if(isModalFor === 'FINISH') {
    return 'Tem certeza de que deseja finalizar a OS?'
  }

  if(isModalFor === 'DRIVER') {
    return "Por não atualizar os seus dados, este motorista encontra-se inativo, deseja adicionar à ordem de serviço mesmo assim?"
  }

  if(isModalFor === 'VEHICLE') {
    return "Por não atualizar os seus dados, este veículo encontra-se inativo, deseja adicionar à ordem de serviço mesmo assim?"
  }

  return ""
}

export const ServiceOrderForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();
  const [isSearchedDriverInActive, setIsSearchedDriverInActive] = useState(false);
  const [isSearchedVehicleInActive, setisSearchedVehicleInActive] = useState(false);
  const [allocateAnyway, setAllocateAnyway] = useState(false);
  const {
    loading,
    setLoading,
    getServiceOrder,
    serviceOrderData,
    setServiceOrderData,
    addServiceOrder,
    updateServiceOrder,
    endServiceOrder
  } = useServiceOrder();

  const [showFinishModal, setShowFinishModal] = useState(false)

  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE);
  
  const isAdmin = userRole?.toString()?.includes('Admin')

  const returnedTitlePage = (location: Location) => {
    if (location.pathname.includes("new")) {
      return "Cadastrar Ordem de Serviço";
    } else if (location.pathname.includes("edit")) {
      return "Editar Ordem de Serviço";
    } else {
      return "Ordem de Serviço";
    }
  };

  const handleSearchClient = async (cnpj: string) => {
    try {
      const { data } = await api.get(`/clientes/cnpj/${cnpj}`);
      setServiceOrderData((prevState) => ({
        ...prevState,
        client: { id: data.data._id, name: data.data.nome },
      }));
    } catch (error) {
      errorMessage("Não foi possível encontrar cliente");
      console.log(error);
    }
  };

  const handleAddDriver = (data: any) => {
    if(data?.id) {
      if(!data?.status) {
        setIsSearchedDriverInActive(true)
      }
      setServiceOrderData((prevState) => ({
        ...prevState,
        cpf: data?.cpf,
        driver: {
          ...prevState?.driver,
          id: data?.id,
          name: data?.name,
          cpf: data?.cpf,
          email: data?.email,
          phoneFirst: data?.phone,
          phoneSecond: data?.secondPhone,
          badge: data?.badge,
          cnhCategory: data?.cnhCategory,
          cnhExpirationDate: data?.cnhExpirationDate,
          cnhNumber: data?.cnh,
          status: data?.status
        },
      }));
    }
  }

  const handleAddVeichle = (data: any, index: number) => {
    if(data?.id) {
      setServiceOrderData((prevState) => {
        let vehicleListDataCopy = [...prevState.vehicles];
        vehicleListDataCopy[index] = {
          id: data?.id,
          name: data?.name,
          renavam: data?.renavam,
          chassi: data?.chassis,
          plate: data?.plate,
          client: data?.cliente?.nome || "",
          color: data?.color,
          createdAt: data?.createdAt,
          status: true,
          responsibleDriver: data?.responsibleDriver,
          ownerName: data?.ownerName,
          vehicleType: data?.vehicleType,
        };
        
        return {
          ...prevState,
          vehicles: vehicleListDataCopy,
        };
      });
    }
  }

  const AddVehicle = () => {
    setServiceOrderData((prevState) => ({
      ...prevState,
      vehicles: [
        ...prevState.vehicles,
        {
          id: "",
          name: "",
          renavam: "",
          chassi: "",
          plate: "",
          client: "",
          color: "",
          createdAt: "",
          status: true,
          ownerName: "",
          responsibleDriver: "",
          vehicleType: {
            id: "",
            name: "",
          },
        },
      ],
    }));
  };

  const [activeStep, setActiveStep] = useState(0);
  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [activeTab, setActiveTab] = useState(0);
  const handleTab = (selectedTab: number) => {
    setActiveTab(selectedTab);
  };
  
  useEffect(() => {
    if (id) {
      getServiceOrder(id);
    }
    if (location.pathname.includes("new")) {
      setLoading(false);
    }
  }, [id, location]);

  useEffect(() => {
    if (!inCreationOrEditing(location)) {
      setActiveStep(5);
    }
  }, [location]);


  const isAbleToNext = () => {
    const routes = serviceOrderData?.schedule?.routes;

    if(activeStep === 0) {
      return !((!!serviceOrderData.client.id && !!serviceOrderData.client.name) && 
        !!serviceOrderData?.schedule.id &&
        !!serviceOrderData?.schedule?.modal &&
        !!serviceOrderData?.schedule?.name &&
        !!serviceOrderData?.schedule?.via &&
        !!serviceOrderData?.schedule?.situation &&
        !!serviceOrderData?.schedule?.typeLoad &&
        !!serviceOrderData?.schedule?.routes.length
      )
    }
    if(activeStep === 1) {
     const checkAllRoutesInfo = routes.map((item) => {
        return (
          !!item?.city && 
          !!item?.collectDate && 
          !!item?.complement && 
          !!item?.id && 
          !!item?.name && 
          !!item?.neighborhood && 
          !!item?.number && 
          !!item?.sender && 
          !!item?.state && 
          !!item?.street && 
          !!item?.zipCode
        )
      })
      return !checkAllRoutesInfo.every(item => !!item)
    }

    if(activeStep === 2) {
      return !serviceOrderData?.checklist?.id
    }
    if(activeStep === 3) {
      return !(
        !!serviceOrderData?.driver?.id && 
        !!serviceOrderData?.vehicles?.length && 
        !!serviceOrderData?.vehicles[0]?.id
      )
    }
    if(activeStep === 4) {
      return false
    }
    else return true
  }

  const canFinishServiceOrder = 
  serviceOrderData.status === SERVICE_ORDER_STATUS.IN_PROGRESS ||
  serviceOrderData.status === SERVICE_ORDER_STATUS.AT_INTERVAL ||
  serviceOrderData.status === SERVICE_ORDER_STATUS.AT_OCCURRENCY

  const chooseModalDescription = () => {
    if(showFinishModal) return IsModalFor.FINISH
    if(isSearchedDriverInActive) return IsModalFor.DRIVER
    if(isSearchedVehicleInActive) return IsModalFor.VEHICLE

    return '' as IsModalFor
  }

  const handleFinishServiceOrder = async () => {
    await endServiceOrder({
      OS_ID: serviceOrderData.id,
      driverID: serviceOrderData.driver.id
    })
  }

  return (
    <Grid container spacing={2}>

      <ModalConfirm 
        isModalOpen={
          isSearchedDriverInActive || 
          isSearchedVehicleInActive || 
          showFinishModal
        }
        closeModal={() => {
          setIsSearchedDriverInActive(false);
          setisSearchedVehicleInActive(false);
          setShowFinishModal(false)
          setAllocateAnyway(false);
        }}
        onOK={() => {
          if(showFinishModal) {
            handleFinishServiceOrder()
            return
          }
          setAllocateAnyway(true);
          setIsSearchedDriverInActive(false);
          setisSearchedVehicleInActive(false);
        }}
        description={
          confirmAnywayTexts(
            chooseModalDescription()
          )
        }
        loading={loading}
      />
      <Grid item lg={3}>
        <Typography className={classes.pageTitle}>Ordem de Serviço {serviceOrderData?.osNumber || ''}</Typography>
      </Grid>
      <Grid item lg={12}>
        <Paper className={classes.paper}>
          <div className={classes.actionBar}>
            <div className={classes.actionBarLeftContent}>
              <IconButton
                onClick={() => navigate(-1)}
                className={classes.buttonBack}
              >
                <KeyboardArrowLeftIcon fontSize="medium" />
              </IconButton>
              <Typography className={classes.pageSubtitle}>
                {returnedTitlePage(location)}
              </Typography>
            </div>
            <div
              style={{
                display: inCreationOrEditing(location) ? "flex" : "none",
              }}
            >
              <Button
                className={classes.buttonDiscard}
                onClick={() => navigate(`/home`)}
              >
                Descartar
              </Button>
            </div>
            <div
              style={{
                display:
                  inCreationOrEditing(location) ||
                  serviceOrderData.status === SERVICE_ORDER_STATUS.FINISHED
                    ? "none"
                    : "flex",
              }}
            >
              {isAdmin && 
                <>
                  <Button
                    className={classes.buttonFinish}
                    onClick={() => {
                      setShowFinishModal(true)
                    }}
                    style={{
                      display: canFinishServiceOrder ? 'flex' : 'none',
                      marginRight: 12,
                      width: 140,
                    }}
                    >
                    Finalizar OS
                  </Button>
                  <Button
                    className={classes.buttonSave}
                    onClick={() => {
                      navigate(`/service-order/${id}/edit`)
                      window.location.reload()
                    }}
                    >
                    Editar
                  </Button>
                </>
              }
            </div>
          </div>
          <div
            className={classes.actionTab}
            style={{
              display: !location.pathname.includes("new") ? "flex" : "none",
            }}
          >
            <Typography
              style={{
                marginRight: 8,
                color: activeTab === 0 ? "#2F4D7C" : "",
              }}
              className={classes.actionTabText}
              onClick={() => handleTab(0)}
            >
              Ordem de Serviço
            </Typography>
            <Typography
              style={{
                marginRight: 8,
                color: activeTab === 1 ? "#2F4D7C" : "",
                display: inCreationOrEditing(location) ? "none" : "flex",
              }}
              className={classes.actionTabText}
              onClick={() => handleTab(1)}
            >
              Checklist
            </Typography>
            <Typography
              style={{
                marginRight: 8,
                color: activeTab === 2 ? "#2F4D7C" : "",
                display: (inCreationOrEditing(location) || !isAdmin) ? "none" : "flex",
              }}
              className={classes.actionTabText}
              onClick={() => handleTab(2)}
            >
              Ocorrências
            </Typography>
            <Typography
              style={{
                marginRight: 8,
                color: activeTab === 3 ? "#2F4D7C" : "",
                display: (inCreationOrEditing(location) || !isAdmin) ? "none" : "flex",
              }}
              className={classes.actionTabText}
              onClick={() => handleTab(3)}
            >
              Intervalos
            </Typography>
            <Typography
              style={{
                marginRight: 8,
                color: activeTab === 4 ? "#2F4D7C" : "",
                display: inCreationOrEditing(location) ? "none" : "flex",
              }}
              className={classes.actionTabText}
              onClick={() => handleTab(4)}
            >
              Monitoramento
            </Typography>
            <Typography
              style={{
                marginRight: 8,
                color: activeTab === 5 ? "#2F4D7C" : "",
                display: (inCreationOrEditing(location) || !isAdmin) ? "none" : "flex",
              }}
              className={classes.actionTabText}
              onClick={() => handleTab(5)}
            >
              Notificações
            </Typography>
            <Typography
              style={{
                color: activeTab === 6 ? "#2F4D7C" : "",
                display: inCreationOrEditing(location) ? "none" : "flex",
              }}
              className={classes.actionTabText}
              onClick={() => handleTab(6)}
            >
              Arquivos
            </Typography>
          </div>
          {(!id || location.pathname.includes("edit")) && (
            <div>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          )}
        </Paper>
      </Grid>
      {/* <Button onClick={iniciarOS}>Iniciar OS</Button> */}
      <Grid
        item
        lg={12}
        style={{ display: inCreationOrEditing(location) ? "flex" : "none" }}
      >
        {activeStep !== 0 && (
          <Button
            className={classes.buttonPreviousStep}
            onClick={handleBackStep}
          >
            Voltar Etapa
          </Button>
        )}
        {activeStep !== 5 && (
          <Button 
            className={classes.buttonNextStep} 
            onClick={handleNextStep}
            disabled={isAbleToNext()}
          >
            Próxima Etapa
          </Button>
        )}
        {activeStep === 5 && (
          <>
            {location.pathname.includes("new") ? (
              <Button
                className={classes.buttonFinish}
                onClick={() => {
                  addServiceOrder({...serviceOrderData, allocateAnyway} as any);
                }}
              >
                {loading ? <Loading/> : "Finalizar"}
              </Button>
            ) : (
              <Button
                className={classes.buttonFinish}
                onClick={() => {
                  updateServiceOrder(serviceOrderData);
                }}
              >
                {loading ? <Loading/> : "Atualizar"}
              </Button>
            )}
          </>
        )}
      </Grid>

      {activeTab === 0 && (
        <Grid item lg={12}>
          <Paper className={classes.paper}>
            {activeStep === 0 && (
              <StepOperationData
                setServiceOrderData={setServiceOrderData}
                handleSearchClient={handleSearchClient}
                serviceOrderData={serviceOrderData}
              />
            )}

            {activeStep === 1 && (
              <StepRoute
                setServiceOrderData={setServiceOrderData}
                serviceOrderData={serviceOrderData}
              />
            )}

            {activeStep === 2 && (
              <StepChecklist
                setServiceOrderData={setServiceOrderData}
                serviceOrderData={serviceOrderData}
              />
            )}

            {activeStep === 3 && (
              <StepDriverAndVehicle
                setServiceOrderData={setServiceOrderData}
                serviceOrderData={serviceOrderData}
                handleAddDriver={handleAddDriver}
                AddVehicle={AddVehicle}
                handleAddVeichle={handleAddVeichle}
              />
            )}
            {activeStep === 4 && (
              <FilesTab 
                files={serviceOrderData.files}
                newOrEdit
                setServiceOrderData={setServiceOrderData}
                serviceOrderData={serviceOrderData}
              />
            )}

            {activeStep === 5 && (
              <StepOverview
                setServiceOrderData={setServiceOrderData}
                serviceOrderData={serviceOrderData}
              />
            )}
          </Paper>
        </Grid>
      )}
      {activeTab === 1 && (
        <ChecklistTab 
          checklist={serviceOrderData?.checklist} 
          serviceOrder={serviceOrderData}
          setServiceOrderData={setServiceOrderData}
        />
      )}
      {(isAdmin && activeTab === 2) && (
        <TabOccurrences/>
      )}
      {isAdmin && activeTab === 3 && <IntervalTab />}
      {activeTab === 4 && <TabMonitoring />}
      {isAdmin && activeTab === 5 && <TabNotifications />}
      {activeTab === 6 && 
        <FilesTab 
          files={serviceOrderData?.files || []} 
          newOrEdit={false} 
          setServiceOrderData={setServiceOrderData}
          serviceOrderData={serviceOrderData}
        />
      }
    </Grid>
  );
};
