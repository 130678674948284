import {FC, useId, useState} from 'react'
import {
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { ServiceOrder } from '../../../../../types/managementServiceOrderTypes';
import DropZoneModal from '../../../../../components/Dropzone/modal';

const chooseType = (type: string) => {
  if(type.includes('image')) {
    return 'image'
  }
  if(type.includes('video')) {
    return 'video'
  }

  return 'document'
}

export type AddFileFormProps = {
  addFile: (fileName: string, required: boolean, type: 'image' | 'video' | 'document', fileKey: string) =>  void
}

const AddFileForm: FC<AddFileFormProps> = ({ addFile }) => {
  const [fileName, setFileName] = useState('')
  const [fileKey, setFileKey] = useState('')
  const [fileType, setFileType] = useState('image')
  const [required, setRequired] = useState(false)
  const [prevewName, setPreviewName] = useState("")

  const id = useId()

  const classes = useStyles();

  const onSubmit = () => {
    if (fileName &&  fileType) {
      addFile(fileName, required, fileType as any, fileKey)
    }
    setFileName('')
    setFileKey('')
    setPreviewName('')
    setRequired(false)
  } 

  return(
    <div style={{ 
      display: 'flex', 
      margin: '20px 0', 
      flexDirection: 'column',
      gap: 12
     }}>
  
      <TextField
        name="fileName"
        value={fileName}
        style={{ maxWidth: 200, marginRight: 12 }}
        onChange={e => setFileName(e.target.value)}
        placeholder="Nome do arquivo"
        variant="outlined"
        size="small"
        fullWidth
        required
      />
      {prevewName && (
        <Typography className={classes.switchText}>
        {prevewName}
      </Typography>
      )}
      <DropZoneModal
        onSave={(key, type, preview) => {
          setFileKey(key)
          setFileType(chooseType(type))
          setPreviewName(preview)
        }}
        label='Inportar Arquivo'
        disabled={!fileName}
        fileKey={`${id}-${fileName}`}
      />

       <Button
          style={{ 
            maxHeight: 40, 
            width: 200,
            opacity: fileName &&  fileType && fileKey ? 1 : 0.7, 
          }}
          disabled={!fileName &&  !fileType && !fileKey}
          className={classes.buttonRegister}
          onClick={() => onSubmit()}
          >
          Adicionar
        </Button>
    </div>
  )
}

export default AddFileForm