import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overviewSectionTitle: {
      color: "#2F4D7C",
      fontWeight: 600,
      fontSize: 18,
    },
    overviewSectionSubtitle: {
      color: "#2F4D7C",
      fontSize: 18,
      fontWeight: 500,
    },
    viewVehicleChecklistTitle: {
      color: "#2F4D7C",
      fontSize: 18,
      fontWeight: 600,
      cursor: 'pointer', 
      margin: '10px 0',
      "&:hover": {
        color: "rgba(47,77,124,0.8)",
      },
    },
    imageName: {
      color: "#2F4D7C",
      fontSize: 16,
    },
    overviewSectionField: {
      color: "#252525",
      fontSize: 18,
    },
    overviewSectionValue: {
      color: "#6A6A6A",
      fontSize: 16,
    },
    stepValue: {
      color: "#76A1E8",
    },
    stepIcon: {
      color: "#2F4D7C",
      "&:hover": {
        cursor: "pointer",
        color: "rgba(47,77,124,0.8)",
      },
    },
    imageInfoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: "0 5px",
      //width: 260,
    },
    row: {
      display: "flex",
    },
    validateStep: {
      cursor: 'pointer',
      color: "#2F4D7C",
      fontWeight: 700,
      "&:hover": {
        textDecoration: 'underline'
      }
    },
    checkboxContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
  
      marginTop: 8,
    },
    checkboxItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    checkboxInput: {
      width: 60,
      marginLeft: 8,
    },
    inputLabel: {
      marginBottom: theme.spacing(1),
      color: "#4E4E4E",
    },
    buttonBack: {
      width: 200,
      fontSize: 14,
      fontWeight: "bold",
      marginRight: 8,
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#FF3C40",
      "&:hover": {
        backgroundColor: "rgba(255,60,64,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    buttonDelete: {
      width: 200,
      fontSize: 14,
      fontWeight: "bold",
      marginLeft: 8,
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 30,
      paddingLeft: 30,
      //color: "#2EA949",
      color: '#fff',
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2EA949",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "rgba(46,169,73,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
  })
);

