import { FC } from "react"
import {
  Delete,
} from "@material-ui/icons/";
import {
  Grid,
  InputLabel,
  TextField,
  Typography,
  Divider
} from "@material-ui/core";

import { useStyles } from "./styles";
import { ImageModel } from "../../../../../types/managementUserTypes";
import { ServiceOrder } from "../../../../../types/managementServiceOrderTypes";
import AddFileForm from "./addFileForm";
import ViewOnlyFiles from "./viewOnlyFiles";

export const chooseTitleAndLabel = (isVideo?: boolean, isDocument?: boolean) => {
  if(isVideo) {
    return {
      title: 'Título do Vídeo',
      label: 'Vídeo'
    }
  }
  if(isDocument) {
    return {
      title: 'Título do Documento',
      label: 'Documento'
    }
  }  

  return {
    title: 'Título da Imagem',
    label: 'Imagem'
  }
}

export type FilesTabProps = {
  newOrEdit: boolean, 
  files: ImageModel[],
  setServiceOrderData: React.Dispatch<React.SetStateAction<ServiceOrder>>;
  serviceOrderData: ServiceOrder
}

const FilesTab: FC<FilesTabProps> = ({ newOrEdit, files, setServiceOrderData, serviceOrderData }) => {
  const classes = useStyles();

  console.log("Files:", files)

    const addFile = (fileName: string, required: boolean, type: 'image' | 'video' | 'document', fileKey: string,) => {
    const _files = [
      ...serviceOrderData.files,
      {
        imageName: "",
        location: {
          latitude: 0,
          longitude: 0,
        },
        dateHour: "00/00/0000",
        required,
        dateScheduled: "00/00/0000",
        imagePath: "",
        fileName,
        imageKey: fileKey,
        order: serviceOrderData.files.length + 1,
        base64: "",
        isVideo: type === 'video',
        isDocument: type === 'document'
      },
    ];

    setServiceOrderData({
      ...serviceOrderData,
      files: _files 
    });
  };

  const deleteFile = (index: number) => {
    const getFiles = serviceOrderData.files.filter((item, i) => {
      return i !== index
    })

    setServiceOrderData({
      ...serviceOrderData,
      files: getFiles 
    });
  }

  const updateFIle = (index: number, key: any, value: any) => {

    const getFiles = serviceOrderData.files.map((item, i) => {
      if(i === index) {
        return {
          ...item,
          [key]: value
        }
      }
      return item 
    })

    setServiceOrderData({
      ...serviceOrderData,
      files: getFiles
    });
  }

  if(!newOrEdit) {
    return <ViewOnlyFiles files={files}/>
  }

  return(
    <Grid item className={classes.formContainer}>
      <Typography className={classes.overviewSectionTitle}>
        Arquivos
      </Typography>

      <AddFileForm 
        addFile={addFile}
      />

      <Divider style={{ margin: "16px 0" }} />

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {files?.map((file: ImageModel, index: number) => {
          return (
            <Grid
              item
              lg={2}
              style={{
                marginTop: 14,
                marginRight: 14
              }}
              key={`file-${index + 1}`}
            >
              
              <Grid 
                item 
                lg={12} 
                style={{
                  display: "flex",
                  alignItems: "center", 
                  justifyContent: "space-between",
                }}
              >
                <InputLabel className={classes.inputLabelStep}>
                  {`${index + 1} - ${chooseTitleAndLabel(file?.isVideo, file?.isDocument).label}`}
                </InputLabel>

                <button
                  type="button"
                  onClick={() => {
                    deleteFile(index)
                  }}
                  style={{
                    width: "auto",
                    height: "auto",
                    backgroundColor: "transparent",
                    padding: 0,
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <Delete htmlColor="#2F4D7C" style={{margin: 0}}/>
                </button>
              </Grid>
              <TextField
                name="fileName"
                value={file.fileName}
                onChange={e => {
                  updateFIle(index, 'fileName', e.target.value)
                }}
                placeholder={chooseTitleAndLabel(file?.isVideo, file?.isDocument).title}
                variant="outlined"
                size="small"
                fullWidth
                required
              />
            </Grid>
          );
        })}
      </div>  
    </Grid>   
  )
}

export default FilesTab