import axios from "axios";
import { LOCAL_STORAGE_KEY } from "../consts";

export const baseURL = process.env.REACT_APP_BASEURL
  ? `${process.env.REACT_APP_BASEURL}/api/v1/`
  : '/api/v1/';

export const api = axios.create({
  baseURL,
});

api.interceptors.request.use(function (config: any) {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_AUTH_TOKEN);
  config.headers.Authorization =   `Bearer ${token}`;

  return config;
});
