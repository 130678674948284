import axios from "axios"

export const uplodaFromPresignedURL = async (url: string, formDataImage: any, contentType: string) => {
  let response  

  try {
    const res = await axios.put(
      url,
      formDataImage,
      {
        headers: {
          "Content-Type": contentType,     
        },
      }
    )

    response = res
  } catch (error: any) {
    const response = error?.response
    console.log("Erro ao tentar fazer o upload dessa imagem aqui, deu merda cara", error, response)
  }

  return response
}