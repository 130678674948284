import { Button, Typography,Dialog, DialogContent } from "@material-ui/core";
import { useStyles } from "./styles";
import { ImageModel } from "../../../../../../types/managementUserTypes";
import { useCallback, useEffect, useState } from "react";
import StepValidationImage from "./stepValidationImage";
import { Loading } from "../../../../../../components/Loading";

type ModalDeleteProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  onOK: (images: ImageModel[]) => void;
  files?: ImageModel[]
  loading: boolean
  validationModalTitle: string
};

const StepValidationModal = ({
  isModalOpen,
  closeModal,
  onOK,
  files,
  loading,
  validationModalTitle
}: ModalDeleteProps) => {
  const classes = useStyles();
  const [images, setImages] = useState(files)

  useEffect(() => {
    setImages(files)
  }, [files])

  const handleAddURLToImage = (name: string, order: number, key: string, type: string) => {
     const getList = images?.map(img => {
      if(img.imageName === name && img.order === order) {
        return {
          ...img,
          imageKey: key,
          imagePath: key,
          isVideo: type === 'video'
        }
      }

      return img
     })

     setImages(getList)
  }

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <DialogContent style={{ marginLeft: 12, marginRight: 12 }}>
        <div>
          <Typography style={{ fontSize: 24, textAlign: "center" }}>
           {validationModalTitle}
          </Typography>
        </div>
      </DialogContent>
      <div
      style={{
        maxHeight: 400,
        overflowY: 'auto'
      }}
      >
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: 'wrap',
          gap: 12,
          marginLeft: 12,
          marginRight: 12,
          paddingBottom: 24,
        }}
      >

      {files?.map((image, index) => {
        return (
          <StepValidationImage
            isVideo={image.isVideo}
            imageName={image.imageName}
            imagePath={''}
            required={image.required}
            onChangeImage={(key, type) => {
              handleAddURLToImage(image.imageName, image.order, key, type)
            }}
          />
        )
      })}
      </DialogContent>
      </div>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: 12,
          marginRight: 12,
          paddingBottom: 24,
        }}
      >
        <Button className={classes.buttonBack} onClick={closeModal}>
          Não desejo
        </Button>
        <Button
          className={classes.buttonDelete}
          variant="text"
          disabled={loading}
          onClick={() => {
            onOK(images as ImageModel[]);
          }}
        >
          {loading ? <Loading/> : 'Sim, desejo'}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default StepValidationModal