import { useContext, useEffect } from "react";

import { AuthUserContext } from "../contexts/AuthUserContext";

import { AppRoutes } from "./app.routes";
import { AuthRoutes } from "./auth.routes";
import { NotificationsContextProvider } from "../contexts/NotificationsContext";
import { LOCAL_STORAGE_KEY } from "../consts";

type PrivateProps = {
  element: JSX.Element;
};

// type PrivateProps = {
//   element: JSX.Element;
// };

const Private = ({ element }: PrivateProps) => {
  const storageIsAuthenticated = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_IS_SIGNED);
  const storageToken = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_AUTH_TOKEN);

  let signed = false;
  if (storageIsAuthenticated ) {
    signed = JSON.parse(storageIsAuthenticated);
  }

  return signed ? element : <AuthRoutes />;
};

export const Routes = () => {
  const { isSigned } = useContext(AuthUserContext);

  // useEffect(() => {
  //   console.log("Routes");
  // }, []);

  return <Private element={
    <NotificationsContextProvider>
      <AppRoutes />
    </NotificationsContextProvider>
  } />;
  // return isSigned ? <AppRoutes /> : <AuthRoutes />;
};
