import { FC } from "react"
import { TbMoodEmpty } from "react-icons/tb"
import { Visibility as VisibilityIcon } from "@material-ui/icons/";
import {
  Grid,
  Typography,
  Paper,
} from "@material-ui/core";

import { EmptyContainer, useStyles } from "./styles";
import { ImageModel } from "../../../../../types/managementUserTypes";
import ImageComponent from "../../../../../components/ImageComponent";

export const chooseTitleAndLabel = (isVideo?: boolean, isDocument?: boolean) => {
  if(isVideo) {
    return {
      title: 'Título do Vídeo',
      label: 'Vídeo'
    }
  }
  if(isDocument) {
    return {
      title: 'Título do Documento',
      label: 'Documento'
    }
  }  

  return {
    title: 'Título da Imagem',
    label: 'Imagem'
  }
}

const chooseTitle = (isDocument?: boolean, isVideo?: boolean) => {
  if(isDocument) return 'Documento'

  if(isVideo) return "Vídeo"

  return 'Imagem'
}

export type FilesTabProps = { 
  files: ImageModel[],
}

const ViewOnlyFiles: FC<FilesTabProps> = ({ files }) => {
  const classes = useStyles();

  console.log("Files:", files)


  return(
    <Grid container spacing={1} className={classes.formContainer}>
    <Grid item lg={12}>
       <Paper className={classes.paper}>
        <Grid item style={{width: "100%"}}>
          <Typography className={classes.overviewSectionTitle}>
            Arquivos
          </Typography>
          {!files.length ? (
            <EmptyContainer bgColor="#fff" color="#2F4D7C">
              {/* <img alt='no-content' src={NoContentImg} /> */}
              <TbMoodEmpty color="#2F4D7C" size={60}/>
              <span>Sem Arquivos</span>
            </EmptyContainer>
          ) : null}
          <div style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: 20 }}>
          
          {files?.length ? files?.map((file, index) => {
              return (
                <div key={`image-${index}`}>
                  <Grid item style={{width: "100%"}}>
                    <Typography className={classes.overviewSectionSubtitle}>
                      {chooseTitle(file?.isDocument, file.isVideo)}
                      <Typography className={classes.imageName}>
                      {file?.fileName || file?.imageName}
                    </Typography>
                    </Typography>
                  </Grid>
                  {file?.imageKey && (
                    <ImageComponent 
                      imageKey={file.imageKey} 
                      imageStyles={{maxWidth: 400}}
                      onlyImage
                      isVideo={file?.isVideo}
                      isDocument={file.isDocument}
                    />
                  )}
                </div>
              )
            })
            : null}

        </div> 
        </Grid>
       </Paper>
    </Grid>
  </Grid>
  )
}

export default ViewOnlyFiles