
import {FC, useState, useEffect, useCallback} from "react"
import { GoogleMap, MarkerF, InfoWindowF, DirectionsRenderer } from "@react-google-maps/api";
import markerMap from "../../assets/position-marker-map.svg";
import circle from "../../assets/gree-maerker.svg";
import iconChegada from "../../assets/icon-chegada.png"
import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { dateToShow } from "../../utils";
import { getAddressFromCoords } from "../../utils/getAddressFromCoords";
import { getDistance } from "../../utils/getDistanceBetweenCoords";

const center = {lat: -11.065370137553252, lng: -48.30048421493055}


interface DriverInfo  {
  createdAt: string;
  inicio: string;
  localizacao: {
    latitude: number;
    longitude: number;
  },
  motorista: {
    nome: string;
    _id: string;
  };
  numero: string;
  os_id: string;
  status: string;
  ultima_atualizacao: string;
  updatedAt: string;
  veiculo: {
    nome: string;
    _id: string;
  };
  _id: string;
}

type DirectionsResult = google.maps.DirectionsResult

const chooseMapIcons = (status: string) => {
  if(status?.toLowerCase() === "finalizada") {
    return iconChegada 
  }

  return markerMap
}

const getLocationEndFormat = (data: any) => {
    return (
      {
        lat: data?.latitude || 0,
        lng: data?.longitude || 0
      }
    )
  }

const transformIncamingData = (data: DriverInfo[]) => {
  const origin = getLocationEndFormat(data[0]?.localizacao || data[1]?.localizacao);
  const destination = getLocationEndFormat(data[data.length - 1]?.localizacao);
  let waypoints: google.maps.DirectionsWaypoint[] | undefined = [];

  data.forEach((item, index) => {
    if(index === 0 || index === data.length - 1) return;

    waypoints?.push({
      location: getLocationEndFormat(item?.localizacao),
      stopover: true,
    })
    }
  )

  return {
    origin,
    destination,
    waypoints
  }

}

interface MapProps {
  markers: DriverInfo[]; 
  selected?: DriverInfo,
  hideDirections?: boolean
  zoom?: number
}

const Map: FC<MapProps> = ({ markers, selected, hideDirections, zoom }) => {
  const classes = useStyles();
  const [selectedMarker, setSelectedMarker] = useState<DriverInfo>({} as DriverInfo);
  const [directions, setDirections] = useState<DirectionsResult>()
  const [selectedAddress, setSelectedAddress] = useState("")

  useEffect(() => {
    if(selected?.localizacao) {
      setSelectedMarker(selected)
    }
  }, [selected])

  useEffect(() => {
    (async() => {

      setSelectedAddress("")
      if(selectedMarker?.localizacao) {
        const {formattedAddress: address} = await getAddressFromCoords(selectedMarker.localizacao.latitude, selectedMarker.localizacao.longitude)
        
        setSelectedAddress(address || "")
      }
    })()
  }, [selectedMarker])

  const fetchDirections = useCallback(() => {
    if(!markers || !markers.length) return;
  
    const service = new google.maps.DirectionsService();

    service.route({
      origin: transformIncamingData(markers).origin,
      destination: transformIncamingData(markers).destination,
      waypoints: transformIncamingData(markers).waypoints,
      travelMode: google.maps.TravelMode.DRIVING
    }, (result, status) => {
      if(status === google.maps.DirectionsStatus.OK && result) {
        setDirections(result);
      }
    })

  }, [markers])

  useEffect(() => {
    fetchDirections()
  }, [markers])

  return(
    <GoogleMap
      options={{
        streetViewControl: true,
        zoomControl: true
      }}
      mapContainerStyle={mapContainer}
      mapContainerClassName="main-map-image"
      onLoad={(map) => {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach((location) => {
          bounds.extend({lat: location.localizacao.latitude ,lng: location.localizacao.longitude});
        })
        map.fitBounds(bounds);
      }}
    >

      {!hideDirections && directions && markers?.length > 1 && <DirectionsRenderer directions={directions} options={{
        markerOptions: {
          visible: false,
        }
      }}/>}

      {
        markers?.map((item, index) => {
          if(!item?.localizacao?.latitude || !item?.localizacao?.longitude) {
            return null
          }
  
          return (
            <MarkerF
              key={index}
              position={{
                lat: item?.localizacao?.latitude,
                lng: item?.localizacao?.longitude
              }} 
              //title={item?.motorista} 
              icon={{ scaledSize: new google.maps.Size(40, 40), url: chooseMapIcons(item.status), scale: 7 }}
              clickable
              onClick={() => setSelectedMarker(item as any)}
            >
            </MarkerF>
          ) })
      }
      {
        selectedMarker?.localizacao && (
          <InfoWindowF
            position={{
              lat: selectedMarker?.localizacao?.latitude,
              lng: selectedMarker?.localizacao?.longitude,
            }}
          
          onCloseClick={() => setSelectedMarker("" as any)}
        >
          <div className={classes.infoWindowContent}>
            <Typography
              className={classes.infoWindowText}
            >
              {`Ordem de serviço - ${selectedMarker?.numero}`}
              
              </Typography> 
            <Typography
              className={classes.infoWindowText}
            >{`Última atualização ${dateToShow(selectedMarker?.ultima_atualizacao, true)}`}</Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography className={classes.infoWindowText}>
                Status:
              </Typography>
              <Typography
                className={classes.infoWindowValue}
                style={{ color: "#2EA949" }}
              >
                {selectedMarker?.status}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography className={classes.infoWindowText}>
                Local:
              </Typography>
              <Typography className={classes.infoWindowValue}>
                {`${selectedMarker?.localizacao?.latitude}, ${selectedMarker?.localizacao?.longitude}`}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography className={classes.infoWindowValue} style={{ maxWidth: 300, marginLeft: 0 }}>
                <span style={{ color: '#2F4D7C', fontWeight: 500 }}>Endereço: {' '}</span>
                {selectedAddress}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography className={classes.infoWindowText}>
                Início:
              </Typography>
              <Typography className={classes.infoWindowValue}>
                {dateToShow(selectedMarker?.createdAt, true)}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography className={classes.infoWindowText}>
                Motorista:
              </Typography>
              <Typography className={classes.infoWindowValue}>
                {selectedMarker?.motorista?.nome}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography className={classes.infoWindowText}>
                Veículo:
              </Typography>
              <Typography className={classes.infoWindowValue}>
                {selectedMarker?.veiculo?.nome}
              </Typography>
            </div>
          </div>
        </InfoWindowF>
        )
      }
    </GoogleMap>
  )
}

export default Map

const mapContainer = {
  width: "100%",
  height: "100%",
  marginTop: 12,
};

