import { makeStyles, createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core"
import styled from 'styled-components';

export const EmptyContainer = styled.div<{ bgColor: string, color: string }>`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;

  background-color: ${({bgColor}) => bgColor};
  padding-top: 20px;

  img{
    margin-top: 40px;
  }

  span{
    margin: 40px 0;
    margin-top: 10px;
    font-size: 20px; 
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    
    text-align: center;
    color: ${({ color }) => color || "#4f4f4f"};
    max-width: 300px;
  }

  @media(max-width: 720px){
    img{
      width: 200px;
    }

    span{
      max-width: 200px;
    }
  }
`

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(1),
    },
    overviewSectionTitle: {
      color: "#2F4D7C",
      fontWeight: 600,
      fontSize: 18,
    },
    overviewSectionSubtitle: {
      color: "#2F4D7C",
      fontSize: 18,
      fontWeight: 500,
    },
    inputLabelStep: {
      marginBottom: theme.spacing(1),
    },
    formSectionTitle: {
      color: "#6A6A6A",
      fontWeight: 700,
    },
    inputLabel: {
      marginBottom: theme.spacing(1),
      color: "#4E4E4E",
    },
    imageTitle: {
      color: "#6A6A6A",
      fontWeight: 500,
      fontSize: 14,
    },
    imageSubtitle: {
      color: "#CDCDCD",
      fontWeight: 500,
      fontSize: 14,
    },
    paper: {
      color: theme.palette.text.secondary,
      flexGrow: 1,
      padding: 10,
    },
    switchContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    switchText: {
      color: "#1D1D1D",
      marginLeft: theme.spacing(1),
      marginRight: 10,
    },
    buttonRegister: {
      width: 140,
      fontSize: 14,
      fontWeight: "bold",
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 30,
      paddingLeft: 30,
      marginTop: 12,
      color: "#FFF",
      textTransform: "capitalize",
      transition: ".5s ease",
      backgroundColor: "#2F4D7C",
      "&:hover": {
        backgroundColor: "rgba(47,77,124,0.8)",
      },
      borderRadius: 5,
      textDecoration: "none",
    },
    formControl: {
      marginLeft: theme.spacing(1),
      minWidth: 120,
    },
    imageName: {
      color: "#2F4D7C",
      fontSize: 16,
    },
    imageInfoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: "0 5px",
      //width: 260,
    },
    stepValue: {
      color: "#76A1E8",
    },
    stepIcon: {
      color: "#2F4D7C",
      "&:hover": {
        cursor: "pointer",
        color: "rgba(47,77,124,0.8)",
      },
    },
  })
);

export const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 36,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      "&$checked": {
        transform: "translateX(20px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#34B870",
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#F66969",
    },
    checked: {},
  })
)(Switch);