import {FC, useCallback, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'

import IMAGE_UPLOAD from '../../assets/image-upload.png'

export interface FileError {
  code: string
  message: string
}

export type DropzoneProps = {
  onUploadFile: (file: File) => void
  onRejectedFile: (file: File, error: FileError) => void
}

const Dropzone: FC<DropzoneProps> = ({ onUploadFile, onRejectedFile }) => {
  // const [selectedFile, setSelectedFile] = useState<File>({} as any)
  
  const onDrop = useCallback((acceptedFiles: FileList) : any => {
      if(acceptedFiles.length) {
        // setSelectedFile(
        //   Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) })
        // )
        onUploadFile(acceptedFiles[0])
        onRejectedFile(undefined as any, undefined as any)
      }
    }, [])

    const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone({
      onDrop,
      accept: {
        'image/*': [],
        'video/*': [],
        'application/pdf': []
      },
      maxSize: 1024 * 10000
    } as any) 

    useEffect(() => {
      if (fileRejections && fileRejections[0]) {
        onRejectedFile(fileRejections[0].file, fileRejections[0].errors[0])
      }
    }, [fileRejections])

    return (
      <label {...getRootProps({
          className: 'dropzone',
          style: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10
          }
        })}
        htmlFor='file'
      >
        <input id="file" {...getInputProps() as any} />
        {
          isDragActive ?
            <p>Solta o arquivo aqui...</p> :
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img 
                style={{
                  width: 100,
                  height: 100,
                }}
                src={IMAGE_UPLOAD} alt="" 
              />
       
              <p style={{ font: 'Barlow, sans-serif', margin: 0, textAlign: 'center' }}>
                Arrasta para aqui um arquivo <br/> 
                Ou clique para selecionar
              </p>
            </div>
        }
      </label>
    )
}

export default Dropzone